import Services from "../../../services";

class RekapitulasiPenyusutanAset {
  getReport(params) {
    return Services.get("/rekapitulasi_nilai_penyusutan_aset", { params });
  }

  getDropdown(params) {
    // params : grup | kategori | jenis | item_aset
    return Services.get("/laporan_daftar_aset/dropdown", { params });
  }
  export(params) {
    return Services.get("/rekapitulasi_nilai_penyusutan_aset/export", {
      params,
    });
  }
}

export default new RekapitulasiPenyusutanAset();
