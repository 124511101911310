import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Dropdown } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
} from 'components';
import {
  DateConvert,
  DecimalConvert,
  PageNumber,
  RupiahConvert,
  TableNumber,
} from 'utilities';
import { RekapitulasiPenyusutanAsetApi } from 'api';
import { ModalFilter, ExportButtonSection } from './Section';
import FileSaver from 'file-saver';

export const LaporanRekapitulasiPenyusutanAset = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const monthOptions = [
    { label: 'Januari', value: '01' },
    { label: 'Februari', value: '02' },
    { label: 'Maret', value: '03' },
    { label: 'April', value: '04' },
    { label: 'Mei', value: '05' },
    { label: 'Juni', value: '06' },
    { label: 'Juli', value: '07' },
    { label: 'Agustus', value: '08' },
    { label: 'September', value: '09' },
    { label: 'Oktober', value: '10' },
    { label: 'November', value: '11' },
    { label: 'Desember', value: '12' },
  ];
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dataSuratJalan, setDataSuratJalan] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      id_grup_aset: location?.state?.surat_jalan?.filter?.id_grup_aset,
      id_kategori_aset: location?.state?.surat_jalan?.filter?.id_kategori_aset,
      id_jenis_aset: location?.state?.surat_jalan?.filter?.id_jenis_aset,
      bulan_perolehan:
        location?.state?.surat_jalan?.filter?.bulan_perolehan ??
        DateConvert(new Date()).defaultMonth,
      tahun_perolehan:
        location?.state?.surat_jalan?.filter?.tahun_perolehan ??
        DateConvert(new Date()).defaultYear,
      id_item_aset: location?.state?.surat_jalan?.filter?.id_item_aset,
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? '1',
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? '10',
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? '1',
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);
    RekapitulasiPenyusutanAsetApi.getReport({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      id_grup_aset: dataFilter?.filter?.id_grup_aset,
      id_kategori_aset: dataFilter?.filter?.id_kategori_aset,
      id_jenis_aset: dataFilter?.filter?.id_jenis_aset,
      bulan_perolehan: dataFilter?.filter?.bulan_perolehan,
      tahun_perolehan: dataFilter?.filter?.tahun_perolehan,
    })
      .then(({ data }) => {
        setDataSuratJalan(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: text.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: '1',
            dataLength: '10',
            totalPage: '1',
            dataCount: '0',
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: 'primary',
          text: 'Hasil dari pencarian: ' + key,
        });
      }, 750)
    );
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    RekapitulasiPenyusutanAsetApi.export({
      id_grup_aset: dataFilter?.filter?.id_grup_aset,
      id_kategori_aset: dataFilter?.filter?.id_kategori_aset,
      id_jenis_aset: dataFilter?.filter?.id_jenis_aset,
      bulan_perolehan: dataFilter?.filter?.bulan_perolehan,
      tahun_perolehan: dataFilter?.filter?.tahun_perolehan,
      tipe,
    })
      .then((res) => {
        const data = res.data.data;
        const urlFile = data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Export Data gagal',
        });
      })
      .finally(() => setLoadingExport(false));
  };

  const getSisaUmurEkonomis = (bulan_perolehan, tahun_perolehan) => {
    const monthDiff =
      parseInt(dataFilter.filter.bulan_perolehan) - parseInt(bulan_perolehan);
    const yearDiff =
      parseInt(dataFilter.filter.tahun_perolehan) - parseInt(tahun_perolehan);
    return monthDiff + yearDiff * 12;
  };

  useEffect(() => {
    setNavbarTitle('REKAPITULASI NILAI PENYUSUTAN ASET');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.id_grup_aset,
    dataFilter?.filter?.id_kategori_aset,
    dataFilter?.filter?.id_jenis_aset,
    dataFilter?.filter?.bulan_perolehan,
    dataFilter?.filter?.tahun_perolehan,
    dataFilter?.filter?.id_item_aset,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>List Data Nilai Penyusutan</b>
        </span>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Aset</ThFixed>
              <Th>Item Aset</Th>
              <ThFixed>Grup Aset</ThFixed>
              <Th>Kategori Aset</Th>
              <Th>Jenis Aset</Th>
              <Th>Bulan Perolehan</Th>
              <Th>Tahun Perolehan</Th>
              <Th>
                Umur Ekonomis <br />
                (Tahun)
              </Th>
              <Th>
                Umur Ekonomis <br />
                (Bulan)
              </Th>
              <Th>
                Sisa Umur Ekonomis <br />
                (Bulan)
              </Th>
              <Th>Nilai Perolehan</Th>
              <Th>Nilai Penyusutan Bulan Ini</Th>
              <Th>Akumulasi Penyusutan</Th>
              <Th>Nilai Buku</Th>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <TdFixed>
                  {PageNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>{val.kode_item_aset ?? '-'}</TdFixed>
                <Td>{val?.nama_item_aset ?? '-'}</Td>
                <Td>{val?.nama_group_aset ?? '-'}</Td>
                <Td>{val?.nama_kategori_aset ?? '-'}</Td>
                <Td>{val?.nama_jenis_aset ?? '-'}</Td>
                <Td>
                  {val?.bulan_perolehan
                    ? monthOptions.find(
                        (res) => res?.value === val?.bulan_perolehan
                      )?.label
                    : '-'}
                </Td>
                <Td>{val?.tahun_perolehan ?? '-'}</Td>
                <Td textRight>{parseInt(val?.umur_ekonomis ?? 0)}</Td>
                <Td textRight>{parseInt(val?.umur_ekonomis_bulan ?? 0)}</Td>
                <Td textRight>
                  {parseInt(val?.sisa_umur_ekonomis_bulan ?? 0) < 0
                    ? 0
                    : parseInt(val?.sisa_umur_ekonomis_bulan ?? 0)}
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(val?.harga_perolehan ?? 0).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.nilai_penyusutan_per_bulan ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.nilai_akumulasi_penyusutan ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(parseFloat(val?.nilai_buku ?? 0).toString())
                      .getWithComa
                  }
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return (
        <Card>
          <Card.Body>
            <DataStatus text="Filter Terlebih Dahulu" />
          </Card.Body>
        </Card>
      );
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
            <ExportButtonSection
              loading={loadingExport}
              disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData('excel')}>
                Excel (.xlsx)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => exportData('pdf')}>
                PDF (.pdf)
              </Dropdown.Item>
            </ExportButtonSection>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          getInitialData={getInitialData}
        />
      )}
    </CRUDLayout>
  );
};
